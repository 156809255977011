.myweb__project-container__proj {
    padding: 18px 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.myweb__project-img {
    width: 50px;
    height: 50px;
}

.myweb__project-content {
    display: flex;

    align-items: flex-start;
    flex-direction: column;
    font-size: 16px;
    color: #fff;
}

.myweb__project-content p {
    font-family: var(--font-family);
}

.myweb__project-name {
    font-size: 30px;
    font-weight: 600;
    text-decoration: underline;
    color: var(--color-text);
}

.myweb__project-dates {
    font-size: 20px;
    color: grey;
}

.myweb__project-description {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    font-family: var(--font-family);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.myweb__project-tools {
    font-size: 20px;
    color: var(--color-text);
}

@media only screen and (max-width: 800px) {
    .workCard{
        width: 100%;
    }
}