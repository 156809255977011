.myweb__role-container__role {
    padding: 18px 18px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.myweb__role-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.myweb__role-content {
    display: flex;
    width: 80%;
    align-items: flex-start;
    flex-direction: column;
    font-size: 16px;
    color: #fff;
    font-family: var(--font-family);
}

.myweb__role-company {
    font-size: 30px;
    font-weight: 600;
}

.myweb__role-dates {
    font-size: 20px;
    color: grey;
}

.myweb__role-description {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    font-family: var(--font-family);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.myweb__role-title {
    font-size: 25px;
    margin: 5px 0px;
}

.myweb__role-tools {
    font-size: 20px;
    color: var(--color-text);
}

@media only screen and (max-width: 800px) {
    .workCard{
        width: 100%;
    }
   
}

@media only screen and (max-width: 600px) {
 
    .myweb__role-container__role {
        padding: 0px 0px;  
    }
}
