.myweb__contact {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-bg);
}

.myweb__contact-channel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

.myweb__contact-channel a {
    display: flex;
    flex-direction: column;
}

.myweb__contact-channel a p {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-size: 25px;
    text-decoration: underline;
    color: var(--color-text);
}

/* Customizing feature component as per needs */
.myweb__about-container .myweb__features-container_feature-text {
    margin-top: 0.5rem;
}

.myweb__contact-container .myweb__project-container_proj-text {
    margin-top: 0.5rem;
}
@media screen and (max-width: 850px) {
    .myweb__contact-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .myweb__contact-heading p {
        margin-top: 1rem;
    }
    .myweb__contact-channel a p {
      
        font-size: 16px;
   
    }
}
