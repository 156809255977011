.myweb__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 1rem;
}

.myweb__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.myweb__navbar-links_logo {
    margin-right: 1rem;
}

.myweb__navbar-links_logo img {
    width: 62.56px;
    height: 62.56px;
}

.myweb__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.myweb__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.myweb__navbar-links_container p,
.myweb__navbar-sign p,
.myweb__navbar-menu_container p {
    color: rgb(255, 255, 255);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.myweb__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.myweb__navbar-menu svg {
    cursor: pointer;
}

.myweb__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.myweb__navbar-menu_container p {
    margin: 1rem 0;
}

.myweb__navbar-menu_container-links-sign {
    display: none;
}
.myweb__navbar-links_container p a, 
.myweb__navbar-menu_container-links p a {
   color: #fff;
   text-decoration: none;
   position: relative;
   transition: color 0.3s ease;
}

.myweb__navbar-links_container p a::after,
.myweb__navbar-menu_container-links p a::after {
   content: '';
   position: absolute;
   width: 0;
   height: 2px;
   bottom: -4px;
   left: 0;
   background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
   transition: width 0.3s ease;
}

.myweb__navbar-links_container p a:hover::after,
.myweb__navbar-menu_container-links p a:hover::after {
   width: 100%;
}

.myweb__navbar-links_container p a:hover,
.myweb__navbar-menu_container-links p a:hover {
   color: #AE67FA;
}

.myweb__navbar-menu_container {
   background: rgba(17, 17, 17, 0.95);
   backdrop-filter: blur(4px);
   transition: all 0.3s ease;
}
@media screen and (max-width: 1050px) {
    .myweb__navbar-links_container {
        display: none;
    }

    .myweb__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .myweb__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .myweb__navbar {
        padding: 2rem;
    }

    .myweb__navbar-sign {
        display: none;
    }

    .myweb__navbar-menu_container {
        top: 20px;
    }

    .myweb__navbar-menu_container-links-sign {
        display: block;
    }
}