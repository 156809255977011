.myweb__features-container__feature {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
}

.myweb__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
}
.feature-img-c{
    padding: 10px;
width: 100%;
}
.feature-img-c img{
    width: 100%;
    object-fit: contain;
    height: 400px;

}

.myweb__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.myweb__features-container_feature-text {
    max-width: 95%;
    display: flex;
}

.myweb__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .myweb__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .myweb__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .myweb__features-container__feature {
        margin: 1rem 0;
    }
}